
$(document).ready(function() {
    // $('input[type="checkbox"].flat-red, input[type="radio"].flat-red').iCheck({
    //     checkboxClass: 'icheckbox_flat-green',
    //     radioClass   : 'iradio_flat-green'
    // });
    // $('[data-toggle="tooltip"]').tooltip(); 
    $('#bt').click(function(){
        $.fn.WBslider = function() {
            return this.each(function() {
            var $_this = $(this),
                $_date = $('input', $_this),
                $_title = $('.setyear', $_this),
                thumbwidth = 50, // set this to the pixel width of the thumb
                yrnow = 100000;
            // set range max to current year
            $_date.attr('max', yrnow);
            $('.endyear', $_this).text( yrnow );
            $_date.val(yrnow - 100000); // -10 years, just because...
    
            $_date.on('input change keyup', function() {
                var $_this = $(this),
                val = parseInt($_date.val(), 10);
    
                if (val < 10001) {
                val = '10000';
                }
                if (val === '') { // Stop IE8 displaying NaN
                val = 0;
                }
    
                $_title.text( val );
    
                var pos = (val - $_date.attr('min'))/($_date.attr('max') - $_date.attr('min'));
                // console.log(pos);
    
                // position the title with the thumb
                var thumbCorrect = thumbwidth * (pos - 0.5) * -1,
                titlepos = Math.round( ( pos * $_date.width() ) - thumbwidth/4 + thumbCorrect );
                $_title.css({'left': titlepos});
    
                // show "progress" on the track
                pos = Math.round( pos * 99 ); // to hide stuff behide the thumb
                // console.log(pos);
                var grad = '#1d4a99';
                $_date.css({'background': grad});
    
            }).on('focus', function() {
                if ( isNaN( $(this).val() ) ) {
                $(this).val(0);
                }
            }).trigger('change');
    
            $(window).on('resize', function() {
                $_date.trigger('change');
            });
            });
        };
        $(function() {
            $('.slider').WBslider();
        });		    
    });
    //responsive mobile menu
    // if ($(window).width() <= 767) {
        $('.menuicon').on('click', function() {
            $('.menusection-menu').addClass('openmenu');
        });
        $('.close-menu').on('click', function() {
            $('.menusection-menu ').removeClass('openmenu');
            $('.nav-submenu').removeClass('open');
            $('.nav-sub-submenu').removeClass('open');
        });
        $(document).on('click', ".menusection-menu .nav-link,.menusection-menu .logout", function() {
            // if ($(this).attr("ng-reflect-router-link") !== undefined || $(this).html() === "Logout") {
            $('.menusection-menu').removeClass('openmenu');
            $('.nav-submenu').removeClass('open');
            $('.nav-sub-submenu').removeClass('open');
            // }
        });


        $('.nav-link-submenu').on('click', function() {
            var submenu = $(this).next('.nav-submenu');
            if (submenu.hasClass('open')) {
                submenu.removeClass('open');
            } else {
                $('.nav-submenu').removeClass('open');
                submenu.addClass('open');
            }
        });
        $('.nav-link-subsubmenu').on('click', function() {
            var subsubmenu = $(this).next('.nav-sub-submenu');
            if (subsubmenu.hasClass('open')) {
                subsubmenu.removeClass('open');
            } else {
                $('.nav-sub-submenu').removeClass('open');
                subsubmenu.addClass('open');
            }
        });
    // }
    

    $(window).scroll(function() {
        var stciky_height = $('.thumbnail-slider-wrapper').closest(".product-details-left").height()-280;
        if($(this).scrollTop() > 150) {
            $('.banner-section-first').addClass('fixed-top');
            // $('.thumbnail-slider-wrapper').addClass('position-sticky');
        } else if($(this).scrollTop() <= 150) {
            $('.banner-section-first').removeClass('fixed-top');
            // $('.thumbnail-slider-wrapper').removeClass('position-sticky');
        }
        AOS.init({
            duration: 1000,
        })

        if($(this).scrollTop() > stciky_height) {
            // $('.thumbnail-slider-wrapper').removeClass('position-sticky');
        }
    });
    $(window).scroll(function() {
        if ($(this).scrollTop() > 700) {
            $('.scrollToTop').fadeIn();
        } else {
            $('.scrollToTop').fadeOut();
        }
    });
    //Click event to scroll to top
    $('.scrollToTop').click(function() {
        $('html, body').animate({ scrollTop: 0 }, 800);
        return false;
    });
    $('.back-to-top').click(function() {
        $('html, body').animate({
            scrollTop: $("#all-product-section").offset().top
        }, 2000);
    });
    $('.card-header').click(function() {
        $(this).parent().parent().parent().children().removeClass('show');
        if ($(this).parent().children().is('.show') != true) {
            $(this).parent().parent().addClass('show');
        } else {
            $(this).parent().parent().removeClass('show');
        }
    });
});

$(document).ready(function(){
    $(".mega-menu .nav-link,.top-menu-items").click(function(){
        $(".mega-menu").css("display", "none");
    });
});

// product zoom
function test1(){
  
}

function test(){
    // $('#lightgallery').lightGallery({
    //     pager: true,
    //     zoom:true
    // });

    var $gallery = $('.my-gallery');
      $gallery.lightGallery({
        thumbnail: true,
        selector: ".lg-item",
      });
}
function zoomActive(){
        $('.xzoom, .xzoom-gallery').xzoom({zoomWidth: 400, title: true, tint: '#333', Xoffset: 15});
        $('.xzoom2, .xzoom-gallery2').xzoom({position: '#xzoom2-id', tint: '#ffa200'});
        $('.xzoom3, .xzoom-gallery3').xzoom({position: 'lens', lensShape: 'circle', sourceClass: 'xzoom-hidden'});
        $('.xzoom4, .xzoom-gallery4').xzoom({tint: '#006699', Xoffset: 15});
        $('.xzoom5, .xzoom-gallery5').xzoom({tint: '#006699', Xoffset: 15});

        //Integration with hammer.js
        var isTouchSupported = 'ontouchstart' in window;

        if (isTouchSupported) {
            //If touch device
            $('.xzoom, .xzoom2, .xzoom3, .xzoom4, .xzoom5').each(function(){
                var xzoom = $(this).data('xzoom');
                xzoom.eventunbind();
            });
            
            $('.xzoom, .xzoom2, .xzoom3').each(function() {
                var xzoom = $(this).data('xzoom');
                $(this).hammer().on("tap", function(event) {
                    event.pageX = event.gesture.center.pageX;
                    event.pageY = event.gesture.center.pageY;
                    var s = 1, ls;
    
                    xzoom.eventmove = function(element) {
                        element.hammer().on('drag', function(event) {
                            event.pageX = event.gesture.center.pageX;
                            event.pageY = event.gesture.center.pageY;
                            xzoom.movezoom(event);
                            event.gesture.preventDefault();
                        });
                    }
    
                    xzoom.eventleave = function(element) {
                        element.hammer().on('tap', function(event) {
                            xzoom.closezoom();
                        });
                    }
                    xzoom.openzoom(event);
                });
            });

        $('.xzoom4').each(function() {
            var xzoom = $(this).data('xzoom');
            $(this).hammer().on("tap", function(event) {
                event.pageX = event.gesture.center.pageX;
                event.pageY = event.gesture.center.pageY;
                var s = 1, ls;

                xzoom.eventmove = function(element) {
                    element.hammer().on('drag', function(event) {
                        event.pageX = event.gesture.center.pageX;
                        event.pageY = event.gesture.center.pageY;
                        xzoom.movezoom(event);
                        event.gesture.preventDefault();
                    });
                }

                var counter = 0;
                xzoom.eventclick = function(element) {
                    element.hammer().on('tap', function() {
                        counter++;
                        if (counter == 1) setTimeout(openfancy,300);
                        event.gesture.preventDefault();
                    });
                }

                function openfancy() {
                    if (counter == 2) {
                        xzoom.closezoom();
                        $.fancybox.open(xzoom.gallery().cgallery);
                    } else {
                        xzoom.closezoom();
                    }
                    counter = 0;
                }
            xzoom.openzoom(event);
            });
        });
        
        $('.xzoom5').each(function() {
            var xzoom = $(this).data('xzoom');
            $(this).hammer().on("tap", function(event) {
                event.pageX = event.gesture.center.pageX;
                event.pageY = event.gesture.center.pageY;
                var s = 1, ls;

                xzoom.eventmove = function(element) {
                    element.hammer().on('drag', function(event) {
                        event.pageX = event.gesture.center.pageX;
                        event.pageY = event.gesture.center.pageY;
                        xzoom.movezoom(event);
                        event.gesture.preventDefault();
                    });
                }

                var counter = 0;
                xzoom.eventclick = function(element) {
                    element.hammer().on('tap', function() {
                        counter++;
                        if (counter == 1) setTimeout(openmagnific,300);
                        event.gesture.preventDefault();
                    });
                }

                function openmagnific() {
                    if (counter == 2) {
                        xzoom.closezoom();
                        var gallery = xzoom.gallery().cgallery;
                        var i, images = new Array();
                        for (i in gallery) {
                            images[i] = {src: gallery[i]};
                        }
                        $.magnificPopup.open({items: images, type:'image', gallery: {enabled: true}});
                    } else {
                        xzoom.closezoom();
                    }
                    counter = 0;
                }
                xzoom.openzoom(event);
            });
        });

        } else {
            //If not touch device

            //Integration with fancybox plugin
            $('#xzoom-fancy').bind('click', function(event) {
                var xzoom = $(this).data('xzoom');
                xzoom.closezoom();
                $.fancybox.open(xzoom.gallery().cgallery, {padding: 0, helpers: {overlay: {locked: false}}});
                event.preventDefault();
            });
           
            //Integration with magnific popup plugin
            $('#xzoom-magnific').bind('click', function(event) {
                var xzoom = $(this).data('xzoom');
                xzoom.closezoom();
                var gallery = xzoom.gallery().cgallery;
                var i, images = new Array();
                for (i in gallery) {
                    images[i] = {src: gallery[i]};
                }
                $.magnificPopup.open({items: images, type:'image', gallery: {enabled: true}});
                event.preventDefault();
            });
        }
}